.gutenberg__editor .components-drop-zone__provider, .gutenberg__editor .components-navigate-regions {
  height: auto;
  position: static; }

.gutenberg__editor .edit-post-layout__content {
  position: static;
  margin-left: 0;
  min-height: calc(250px - 56px);
  height: auto;
  overflow-y: auto; }

.gutenberg__editor .edit-post-layout {
  height: auto;
  position: static; }

.gutenberg__editor .edit-post-pinned-plugins {
  display: -webkit-box;
  display: flex; }

.gutenberg__editor .edit-post-visual-editor {
  padding-top: 25px;
  padding-bottom: 75px;
  height: auto; }

@media (min-width: 600px) {
  .gutenberg__editor .edit-post-header {
    position: static;
    top: 0; } }

.gutenberg__editor.fixed-height .components-drop-zone__provider, .gutenberg__editor.fixed-height .components-navigate-regions {
  height: 100%; }

.gutenberg__editor.fixed-height .edit-post-layout__content {
  height: calc(100% - 56px); }

.gutenberg__editor.fixed-height .edit-post-layout {
  height: 100%; }

.gutenberg__editor.fixed-height .editor-writing-flow {
  height: auto; }

.gutenberg__content .wp-block-columns {
  display: -webkit-box;
  display: flex; }

.laraberg__editor {
  width: auto;
  overflow: hidden;
  border: 1px solid #e2e4e7;
  box-sizing: border-box;
  min-height: 400px;
  height: auto; }
  .laraberg__editor .components-notice-list {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100; }
  .laraberg__editor .edit-post-header {
    z-index: 100; }
  .laraberg__editor .edit-post-sidebar {
    position: absolute; }
  .laraberg__editor .editor-inserter__manage-reusable-blocks {
    display: none; }
  .laraberg__editor iframe {
    border: 0;
    max-width: 100%; }
  .laraberg__editor .laraberg-sidebar-content {
    padding: 15px;
    margin: unset; }

.gutenberg__content .alignleft {
  float: left;
  margin-right: 1rem; }

.gutenberg__content .alignright {
  float: right;
  margin-left: 1rem; }

.gutenberg__content h1,
.gutenberg__content h2,
.gutenberg__content h3,
.gutenberg__content h4,
.gutenberg__content h5,
.gutenberg__content h6 {
  margin-bottom: 24px; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%; }

.laraberg-sidebar {
  display: none; }
